import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';

export const ReviewWrapper = styled.div`
  gap: 32px;
  width: 100%;
  display: block;
  justify-content: space-between;
`;

export const ReviewContainer = styled.div`
  gap: 32px;
  padding: 32px;
  display: flex;
  max-width: 770px;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  background: ${colors.base.white};
`;
